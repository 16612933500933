<!-- 知识讲堂详情 -->
<template>
  <div id="target">
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{list.IssueTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')}}</div>
      <!-- <div class="rikind">{{ list.AKName }}</div> -->
      <div v-if="list.ThemeImg" class="essayImg">
        <img :src="list.ThemeImg" alt="" style="width:90%;margin:15px 5%" />
      </div>
      <div class="essayContent" v-html="list.Content"></div>
      <div class="video" v-if="list.Video">
        <video style="width: 100%" :src="list.Video" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
  </div>
</template>
<script>
import { WeGetGoodNews } from "@/api/Hyapi";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from 'vant';
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      gnId: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
    };
  },
  created() {
    window.scroll(0, 0);
    // 获取并保存openid
    // if (this.$route.query["openid"]) {
    //   setOpenId(this.$route.query["openid"]);
    // }
    
  },
  methods: {
    // 获取活动详情
    getRealInfo() {
      this.gnId = this.$route.params.Id;
      WeGetGoodNews({ gnId: this.gnId }).then((res) => {
        this.list = res.data.data;
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>